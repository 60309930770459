@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/FontsFree-Net-SFProDisplay-Bold/FontsFree-Net-SFProDisplay-Regular.ttf");
  font-style: normal;
  /* font-weight: normal; */
}
* {
  -webkit-font-smoothing: antialiased;
}

html {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  font-size: 1rem;
  /* font-weight: 400; */
  line-height: 1.5;
  color: #212529;
  text-align: left;
  /*background-color: #F6F6F6;*/
  background-color: #0091ff;
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "SF Pro Display", sans-serif !important;
  position: relative;
  height: 100%;
  overflow-y: scroll !important;
}

.main_wrapper {
  display: flex;
  background-color: #ecf7ff;
  align-items: center;
  position: relative;
}

.inner-box-contents {
  /* width: 75%; */
  width: 1191px;
  background-color: #fff;
  min-height: 600px;
  -webkit-box-shadow: 9px 12px 12px -10px rgba(100, 100, 100, 0.4);
  -moz-box-shadow: 9px 12px 12px -10px rgba(100, 100, 100, 0.4);
  box-shadow: 9px 12px 12px -10px rgba(100, 100, 100, 0.4);
  margin: 0 auto;
  border-radius: 20px;
  position: relative;
  height: 624px;
}

.left-content {
  background: url(../images/banner-bunp.png);
  height: 100%;
  /* width: 45%; */
  min-width: 550px;
  max-width: 550px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 60px;
  padding-right: 100px;
  float: left;
}

.left-content:after {
  content: "";
  background-image: linear-gradient(
    to right top,
    rgba(0, 145, 255, 0.93),
    rgba(0, 145, 255, 0.91),
    rgba(0, 136, 255, 0.9),
    rgba(87, 179, 255, 0.73),
    rgba(68, 173, 253, 0.58)
  );
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 20px;
}

.logo {
  margin-top: 120px;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}

.welcome-note {
  position: relative;
  z-index: 1;
}

.welcome-note h3 {
  font-size: 30px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}
.captal {
  text-transform: uppercase;
}

.welcome-note h3 strong {
  font-weight: 800;
}

.welcome-note h3:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: -15px;
  left: 0;
}

.welcome-note p {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  opacity: 0.87;
  line-height: 29px;
}

.right-content {
  padding: 30px;
  float: left;
  width: 641px;
  height: 100%;
}

.price-section {
  border-radius: 10px;
  /*background-color: #E4EEFF;*/
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f3f6ff),
    color-stop(#f0f8ff),
    color-stop(#ecfbff),
    color-stop(#eafdff),
    to(#e9fffe)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f3f6ff,
    #f0f8ff,
    #ecfbff,
    #eafdff,
    #e9fffe
  );
  background-image: linear-gradient(
    to right,
    #f3f6ff,
    #f0f8ff,
    #ecfbff,
    #eafdff,
    #e9fffe
  );
  margin-bottom: 50px;
  padding: 15px;
}

.offer-tag {
  min-width: 150px;
  padding: 10px 13px 10px 0;
  background-color: #4d4d4d;
  display: inline-block;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.offer-tag:before {
  position: absolute;
  content: "";
  border-width: 0px 0px 7px 50px;
  border-color: #fd000017 #292929 #292929 #00f0;
  border-style: solid;
  top: -7px;
  left: 0px;
}

.offer-inner-tag {
  border: 1px dashed #fff;
  border-left: none;
  padding: 5px 15px 5px 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.offer-inner-tag p {
  font-size: 12px;
  margin-bottom: 0;
  color: #fff;
  text-transform: uppercase;
}

.offer-inner-tag h2 {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  color: #fff;
}

.offer-inner-tag h2 small {
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
}

.actual-price {
  border-radius: 10px;
  /*background-image: linear-gradient(to right, #f3f6ff, #f0f8ff, #ecfbff, #eafdff, #e9fffe);*/
  /*float: right;*/
  width: 63%;
  display: inline-block;
  /*margin:12px 10px 0px 0;*/
  text-align: center;
}

.exact-amount span {
  position: relative;
  color: #2e2e2e;
  font-size: 19px;
  font-weight: 300;
  margin-right: 10px;
}

.exact-amount span:after {
  position: absolute;
  content: "";
  background-color: #000;
  top: 11px;
  left: -5px;
  width: 115%;
  height: 2px;
}

.offerSession {
  position: relative;
  background-color: #fff;
  display: inline-block;
  width: 37%;
  text-align: center;
  border-radius: 4px;
  box-shadow: 2px 5px 15px -6px #ddd;
}

.offerSession sup {
  position: absolute;
  top: -10px;
  background-color: #00d3de;
  padding: 14px;
  left: 20px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
}

.discountPercentage {
  font-size: 30px;
  font-weight: 700;
  padding-top: 15px;
}

.discountPercentage small {
  font-size: 70%;
}

.exact-amount strong {
  font-weight: 700;
  color: #2e2e2e;
  font-size: 19px;
}

.exact-amount {
  padding-bottom: 5px;
  padding-top: 5px;
}

.exact-amount p {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 13px;
  color: #4d4d4d;
}

.client-info ul a {
  color: rgba(100, 100, 100, 0.6) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 0 !important;
}

.client-info ul li {
  position: relative;
  padding: 0px 40px 0 0;
}

.client-info ul li a:hover {
  border-color: transparent !important;
  cursor: pointer;
}

.client-info ul li:nth-child(2) {
  padding-left: 30px;
}

.client-info ul li:after {
  position: absolute;
  background-color: #bbbbbb94;
  height: 30px;
  width: 2px;
  top: -5px;
  right: 0;
  content: "";
}

.client-info ul {
  margin-bottom: 30px !important;
  border-bottom: none !important;
}

.client-info ul li:nth-child(2):after {
  background-color: #fff;
}

.client-info ul a.active,
.client-info ul .show > a {
  background-color: transparent !important;
  color: #0091ff !important;
  font-weight: 500 !important;
  /*font-size: 16px;*/
  padding: 0 !important;
  border-color: transparent !important;
}
/* changes */
.input-field {
  margin-bottom: 15px;
}

.input-field label {
  font-weight: 400;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.81);
}

.input-field input {
  border-radius: 10px;
}

.input-field .StripeElement {
  border-radius: 10px;
  min-height: 45px;
  padding-top: 12px;
}

.input-field input:focus {
  background-color: #f3f3f3;
  outline: none;
  box-shadow: none;
  border-color: #80bdff00;
}

.calucation-area {
  width: 95px;
  display: inline-block;
  margin-bottom: 15px;
}

.btn-section button {
  background-color: #003660;
  padding: 15px 30px;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  border-radius: 25px;
}

.rate-amount {
  min-height: 45px;
  background-color: #f3f3f3;
  line-height: 2.9;
  border-radius: 10px;
  text-align: center;
  padding: 0px 15px;
  transition: all 0.5s;
}

.btn-section button:hover {
  background-color: rgba(0, 54, 96, 0.95);
  box-shadow: 1px 1px 6px 0px #b5b5b5;
  transition: all 0.5s;
}

.btn-section button:focus {
  outline: 0px auto -webkit-focus-ring-color;
}

.mennubar {
  min-height: 110px;
  /*background-color: #FFFFFF;*/
  background-color: #0a95ff;
  box-shadow: 0px 0px 10px -5px #989898;
  margin-bottom: 30px;
}

.mennubar nav {
  background-color: transparent;
  padding: 42px 0 25px;
  width: 100%;
}

.upLink a {
  padding: 10px 30px;
  margin: 0 10px;
  color: #393939;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #fff;
}

.upLink a {
  text-decoration: none;
}

.contactbtn {
  background-color: #0091ff;
  /*background-image: linear-gradient(to right, #0091ff, #47a7ff, #70bcff, #97d0ff, #bee3ff);*/
  text-transform: uppercase;
  font-size: 14px;
  color: #fff !important;
  border-radius: 25px;
  text-transform: capitalize;
}

.contactbtn:hover {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 1px 6px 0px #b5b5b5;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  color: #0091ff !important;
  text-decoration: none;
}

.jumbotron-custom {
  max-width: 910px;
  width: 100%;
  margin: 30px auto 60px;
  /*background-color: #fff;*/
  border-radius: 14px;
  padding: 50px 50px;
  /*box-shadow: 0px 0px 10px -5px #BFBFBF;*/
}

.head-title {
  text-align: center;
  margin-bottom: 30px;
}

.head-title h2 {
  font-size: 30px;
  /*color: #212121;*/
  color: #fff;
  font-weight: 700;
}

.head-title h6 {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.84);
  font-weight: 500;
}

.jumbotronContent {
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

.jumbotronContent p {
  color: rgba(255, 255, 255, 0.56);
  font-size: 16px;
  line-height: 2;
}

.coyLink {
  margin-top: 30px;
}

.copyLink p {
  background-color: #41aeff;
  border-radius: 10px;
  display: inline-block;
  padding: 9px 20px;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
}

.copyLink button {
  display: block;
  font-size: 13px;
  /*color: #212121;*/
  font-weight: 500;
  margin: 0 auto;
  border: none;
  background-color: transparent;
  color: #fff;
  text-transform: uppercase;
}

.copyLink a:hover {
  text-decoration: none;
}

button:focus {
  outline: 0px auto -webkit-focus-ring-color;
}

.primary-button {
  color: #fff;
  padding: 15px 40px;
  border-radius: 36px;
  font-size: 16px;
  background-image: linear-gradient(
    to right,
    #0091ff,
    #379dfb,
    #46a4f7,
    #7ac3ff,
    #baddf7
  );
  border: none;
  text-transform: uppercase;
}

.buttonBig {
  margin-top: 50px;
  text-align: center;
}

.primary-button:hover {
  color: #fff;
}

.footer-link {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.footer-link p {
  /*color: rgba(33, 33, 33, 0.71);*/
  color: rgba(255, 255, 255, 0.71);
  font-size: 18px;
}

.footer-link a {
  color: #fff;
}

.footer-link a:hover {
  text-decoration: none;
}

.head-title p {
  color: rgba(255, 255, 255, 0.71);
  font-size: 14px;
}

.signUpsection {
  max-width: 660px;
  width: 55%;
  position: relative;
}

.signUpsection .jumbotronContent {
  text-align: left;
  width: 100%;
}

/********************************************/

/* The container */
.policy-section label {
  display: inline-block;
  position: relative;
  padding-left: 0px;
  margin-bottom: 12px;
  font-weight: 300 !important;

  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.policy-section label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid #fff;
  background: #fff;
}

/* On mouse-over, add a grey background color */
.policy-section label:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.policy-section label input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.policy-section label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.policy-section label .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.policy-section {
  font-size: 12px;
  margin-top: 20px;
  font-weight: 300;
  /* color: rgba(255, 255, 255, 0.81); */
}

.policy-section a {
  font-size: 12px;
  color: #fff;
}

.buttonBig .signIn {
  margin-top: 15px;
}

.buttonBig .signIn p {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.71);
}

.buttonBig .signIn p a {
  color: #fff;
  font-weight: 700;
}

a:hover {
  text-decoration: none;
}

.action-btn {
  text-align: center;
}

.kickOff {
  max-width: 606px;
  width: auto;
}

footer {
  height: 160px;
  /*background-color: #fff;*/
  background-color: #1d9eff;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 40px;
}

.footer-links ul {
  padding-left: 0;
  text-align: center;
}

.footer-links ul li {
  display: inline-block;
  width: 14%;
}

.footer-links ul li:last-child {
  width: 35.8%;
}

.footer-links ul li strong {
  display: block;
  font-weight: 500;
  color: #fff;
  font-size: 15px;
  padding-bottom: 12px;
  position: relative;
  margin-bottom: 10px;
}

.footer-links ul li strong:after {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  content: "";
  height: 2px;
  width: 22px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.footer-links ul li a {
  color: #212121;
  font-weight: 300;
  font-size: 13px;
  margin-right: 10px;
}

.Social-icon {
  padding: 5px;
}

.width-50 {
  width: 48%;
  display: inline-block;
}

.width-50:nth-child(1) {
  margin-right: 16px;
}

.width-60 {
  width: 56%;
  display: inline-block;
  margin-right: 21px;
}

.width-40 {
  width: 40%;
  display: inline-block;
  float: right;
}

.width-35 {
  width: 44%;
  display: inline-block;
  float: left;
  margin-bottom: 35px;
  margin-right: 21px;
}

.width-50M {
  width: 50%;
}

.btn-section {
  float: left;
  width: 100%;
}

.error-alert {
  position: relative;
}

.error-alert label {
  color: #ffb8b8;
}

.error-alert input {
  border-color: #ffc8c8;
  background-color: #fff6f6;
  background-image: url("../images/error-icon.png");
  background-repeat: no-repeat;
  background-position: 96% 50%;
}

.error-alert .alert-message {
  position: absolute;
  bottom: -20px;
  left: 5px;
  color: #f23737;
  font-size: 13px;
}

.alert-message_email {
  bottom: -20px;
  position: absolute;
  left: 5px;
  color: #f23737;
  font-size: 13px;
}

/*.required-alert label {*/
/*position: relative;*/
/*}*/

/*.required-alert label:after {*/
/*content: '(Required)';*/
/*position: absolute;*/
/*top: 0;*/
/*left: 120%;*/
/*color: #FF2C2C;*/
/*}*/

.required-alert input {
  border-color: #ff2c2c;
}

.client-info {
  position: relative;
}

.alert-position {
  position: absolute;
  width: inherit;
  top: 62px;
}

.alert-success {
  color: #155724;
  background-color: #fafffb;
  border-color: #c3e6cb;
}

.custom_small_err {
  width: 250px;
}

.alert-danger {
  border-color: #ffc8c8;
  background-color: #fff6f6;
  color: #ff2c2c;
}

.alert-warning {
  background-color: #fffbed;
}

.client-info ul li {
  position: relative;
  padding: 0px 30px 0 0;
}

.client-info ul a {
  font-size: 12px !important;
}

.offerSession {
  width: 37%;
}

.mt-10 {
  margin-top: 10px;
}

.hidden {
  position: fixed;
  bottom: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  transform: scale(0);
}

.error-alert .relative-left {
  padding-left: 10px;
  position: static;
}

.loader,
.loader:before,
.loader:after {
  background: #bbbbbb;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
}

.loader {
  text-indent: -9999em;
  margin: auto;
  position: relative;
  font-size: 4px;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:after {
  left: 1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #bbbbbb;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #bbbbbb;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #bbbbbb;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #bbbbbb;
    height: 5em;
  }
}

.adjust {
  height: 18px;
  width: 100px;
  display: flex;
  justify-content: center;
}

.mt {
  margin-top: 50px;
}

.InputElement {
  font-family: "Montserrat", sans-serif;
}
f.kickOff-wrapper .alertCoupon {
  position: relative;
}

.upLink {
  float: right;
}

.testAlert {
  min-height: 33px;
  padding: 5px 15px;
  font-size: 13px;
  /*position: relative;*/
  padding-right: 30px;
  top: 72px;
}

.testAlert button {
  padding: 5px 10px 5px 5px !important;
}

.changebtnColor {
  background-color: transparent;
  background-image: none;
  border: 2px solid #fff;
}

.changebtnColor:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.95);
}

.signColor .input-field label {
  color: #212529;
}

.link-copy {
  background: transparent !important;
}

.link-copy:hover {
  background: #007bff !important;
  color: #ffffff !important;
}
.calucation-area-first {
  float: left;
}

.body-contents {
  height: 100vh;
  font-family: "Montserrat", sans-serif;
}

.body-contents .header {
  box-shadow: 0 0 8px #8d8d8d;
}

.body-contents .header .container .header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
}

.body-contents .header .container .header-inner .contact-div p {
  color: #565656;
  text-align: right;
}

.body-contents .header .container .header-inner .contact-div p a {
  color: #0091ff;
}

.body-contents .header .container .header-inner .contact-div p a:hover {
  text-decoration: none !important;
  color: #008bf5;
}

.body-contents .contents-main .container .error-div {
  padding: 70px 0 100px;
}

.body-contents .contents-main .container .error-div .error-div-inner {
  max-width: 1080px;
  text-align: center;
  background: url(../../assets/images/bakground.png) no-repeat center/cover;
  margin: auto;
  padding: 100px 235px;
}

.body-contents .contents-main .container .error-div .error-div-inner h1 {
  font-size: 229px;
  color: #0091ff;
  font-weight: 700;
}

.body-contents .contents-main .container .error-div .error-div-inner h2 {
  font-size: 35px;
  font-weight: 600;
}

.go-home__btn {
  background: #f1f9ff;
  padding: 13px 40px;
  border-radius: 50px;
  border: 1px solid #0091ff;
  margin-top: 10px;
  color: #0091ff;
}

.go-home__btn:hover {
  color: #f1f9ff;
  background: #0091ff;
}

.go-home__btn:focus {
  box-shadow: none;
}

.error-logo {
  max-height: 50px;
}

/* html edit */

body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

.left-div {
  width: 46%;
  /* min-width: 870px; */
}
.form-div .input-field label {
  margin-bottom: 0 !;
}

.right-div {
  width: 54%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-right: 60px;
    padding-left: 60px; */

  position: relative;
  padding: 20px;
}
.title-h2 {
  margin-bottom: 80px;
}

/* .app-icon-btn a {
    margin: 0px 20px;
} */

.app-icon-app {
  margin-right: 40px;
}

.app-icon-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 67px;
}

.web-link {
  text-transform: lowercase;
  font-size: 22px;
}

.web-link a {
  text-decoration: none;
  color: #0090ff;
}

.web-link a:hover {
  text-decoration: none;
  color: #0062ff;
}

.web-link h3 {
  font-weight: 600;
  font-size: 29px;
  text-transform: lowercase;
}

.left-banner-img {
  position: relative;
}

.left-banner-img > img {
  height: 100vh;
  object-fit: cover;
}

.left-logo-div {
  position: absolute;
  top: 51px;
  left: 56px;
  max-width: 300px;
}

.right-text-div {
  color: #1a1919;
}

.right-text-div h2 {
  font-size: 86px;
  font-weight: 700;
  line-height: 109px;
  margin-bottom: 30px;
  margin-top: 26px;
}

.right-text-div h3 {
  font-size: 50px;
  font-weight: 300;
  line-height: 70px;
  margin-bottom: 53px;
}

.contact-link p {
  text-decoration: none;
  font-size: 15px !important;
  font-weight: 500;
  line-height: 70px;
  color: #1a1919;
  margin-bottom: 0px;
}
.contact-link a {
  font-size: 15px !important;
  font-weight: 500;
  line-height: 70px;
  color: #1a1919;
}

.contact-link {
  position: absolute;
  bottom: 215px;
  left: 0;
  right: 0;
  text-align: center;
}

/* 11 end */

/* 12 start */

.coupon-left {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start !important;
  padding: 150px 27px 0 150px;
}
.coupon-banner-img {
  margin-bottom: 20px;
}

.coupon-logo-div {
  position: absolute;
  top: 30px;
  left: 145px;
}
.coupon-logo-div img {
  max-height: 90px;
}

.coupon-header-text {
  margin-top: 0px;
  margin-bottom: 20px;
  max-width: 750px;
}

.coupon-header-text h2 {
  font-size: 65px;
  line-height: 75px;
  font-weight: 500;
  max-width: 780px;
  margin: auto;
}

.coupon-left {
  width: 52%;
}

.coupon-right {
  position: relative;
  width: 48%;
  background-color: #f2f9fd;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-left: 75px;
  padding-right: 75px;
}

.coupon-form-div {
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
  position: relative;
  background-color: #88c4ec;
  border-radius: 36px;
  padding: 14px 0px 17px;
  color: white;
  margin-bottom: 350px !important;
}

.form-div {
  padding: 0px 60px;
}

.coupon-form-div label {
  font-size: 13px;
  color: #ffffff;
}

.coupon-form-div input {
  border-radius: 12px;
  border: none;
  padding-left: 20px;
  background: white;
}
@font-face {
  font-family: "SF pro";
  src: url(../fonts/FontsFree-Net-SFProDisplay-Bold/FontsFree-Net-SFProDisplay-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "SF pro";
  src: url(../fonts/FontsFree-Net-SFProDisplay-Bold/FontsFree-Net-SFProDisplay-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "SF pro";
  src: url(../fonts/FontsFree-Net-SFProDisplay-Bold/FontsFree-Net-SFProDisplay-Medium.ttf);
  font-weight: 500;
}

.coupon-form-div .form-check input {
  height: 14px;
  width: 14px;
  border-radius: 0;
  border: none;
  padding-left: 0px;
}
.coupon-form-div .form-check a {
  color: white;
}
.form-div a {
  color: white;
  font-size: 13px;
}
.policy-section {
  font-size: 13px;
  margin-bottom: 5px;
}
.policy-section label {
  margin-bottom: 0px;
}

.coupon-form-title {
  padding: 0px 10px;
  margin-bottom: 10px !important;
  font-size: 33px;
  font-weight: 400;
}

.coupon-btn-div {
  margin-top: 44px;
  margin-left: auto;
  margin-right: auto;
}

.coupon-btn-redeem {
  background-color: #034469;
  border: 1px solid #707070;
  border-radius: 14px;
  padding: 11px 22px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  color: white;
  text-decoration: none;
}
.coupon-btn-redeem:hover {
  border: 1px solid #707070;
  background-color: #0e5e8d;
  color: white;
}

.form-items {
  margin-bottom: 40px;
}
.welcome-wrapper {
  background-color: white;
  font-family: "SF pro", sans-serif;
}
.coupon-wrapper {
  background-color: white;
  font-family: "SF pro", sans-serif;
  padding: 0px 0px;
}
.coupon-buttonBig {
  position: absolute;
  bottom: -75px;
  margin-top: 44px;
  margin: auto;
  left: 0px;
  right: 0px;
  text-align: center;
}
.coupon-alert {
  width: 80%;
  border-radius: 20px;
  position: absolute;
  top: 30px;
  left: 0px;
  right: 0px;
  margin: auto;
  font-size: 22px;
  text-transform: capitalize;
}
.form-check-input {
  cursor: pointer;
}
.policy-section .form-check-input {
  margin-left: -1.25rem !important;
}
.form-check {
  padding-left: 1.5rem;
}

.contact-link-wrapper {
  position: relative;
}

.policy-section {
  font-weight: 300;
}
.policy-section strong {
  font-weight: 600;
}
.wrapper {
  height: 100vh;
}
.kickoff-contact {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}
.kickoff-contact p {
  font-size: 28px;
}
.kickoff-contact a {
  font-size: 28px;
  color: #212529;
}

/* kickoff */
.kickOff-wrapper {
  width: 100vw;
  background-color: #fff;
  overflow: hidden;
}
.kickOff-wrapper-row {
  min-height: calc(100vh - 107.712px);
}
.left {
  margin-top: 32px;
  margin-right: 100px;
  display: flex;
  justify-content: end;
}
.kickOff-left-content {
  display: flex;
  width: 450px;
  flex-direction: column;
  gap: 32px;
  margin-top: 48px;
}
.right-step1 {
  margin-top: 140px;
  margin-left: 100px;
  display: flex;
}
.right-step2 {
  margin-top: 80px;
  margin-left: 100px;
  display: flex;
}
.right-step3 {
  margin-top: 10px;
  margin-left: 100px;
  display: flex;
}
.kickOff-form-container {
  display: flex;
  padding: 24px 36px 36px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  background: #fff;
  width: 450px;
  margin-bottom: 15px;
}
.kickOff-form-header {
  color: #1a1919;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.kickOff-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.kickOff-slider-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 10px;
}
.kickOff-slider {
  border-radius: 4px;
  width: 290px;
  display: flex;
  align-items: center;
  margin-top: 18px;
}

.kickOff-divider {
  background: #eaeaea;
  width: 100%;
  height: 1px;
}
.right-container {
  background: #80c5ee;
  padding: 0px;
}
.kickOff-logo {
  width: 107.552px;
  height: 36px;
}
.kickOff-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.kickOff-heading > p {
  color: #1a1919;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 54px; /* 112.5% */
  margin-bottom: 0px;
}
.kickOff-heading > span {
  color: #1a1919;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
}
.kickoff-supporting-text {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-self: stretch;
}
.kickoff-supporting-text > p {
  color: #1a1919;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}
.kickoff-supporting-text > a {
  color: #0377c4;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}
.kickOff-user-detail {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 2px;
  width: 100px;
}
.kickOff-user-count {
  color: #1a1919;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: right;
  width: 100px;
}
.kickOff-pricing {
  width: 100%;
}
.kickOff-price-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.kickOff-price-total {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
}
.kickOff-price-per-user {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.kickOff-price-per-user > p {
  margin: 0px;
  color: #1a1919;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kickOff-price-per-user > span {
  color: #606060;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kickOff-price > p {
  margin: 0px;
  color: #1a1919;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kickOff-price > span {
  color: #606060;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: line-through;
}
.kickOff-total {
  margin: 0px;
  color: #1a1919;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.kickOff-form-button {
  display: flex;
  padding: 20px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #fa7701;
  background: #fa7701;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.kickOff-form-button > a {
  color: #fff;
}
.kickOff-help {
  color: #1a1919;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 18px;
}
.kickOff-help > a {
  color: #1a1919;
  font-weight: 600;
}
.kickOff-help .underlined {
  text-decoration: underline;
}
.kickOff-normal-header-text {
  color: #1a1919;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.kickOff-back {
  display: flex;
  justify-content: start;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
}
.kickOff-back > button {
  display: flex;
  align-items: center;
}
.kickOff-back > button {
  cursor: pointer;
  border: none;
  background: none;
  color: #1a1919;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
  padding: 0px;
}
.kickOff-sub-header {
  color: #1a1919;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kickOff-form-title-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.kickOff-form-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.kickOff-form-input-full {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  padding: 0px;
  /* width: 450px; */
}
.kickOff-label {
  color: #1a1919;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kickOff-label-container {
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
}
.kickOff-form-input > input {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: #e7f6ff;
  border: none;
  padding: 12px 16px;
  /* height: 43px; */
}
.kickOff-form-input-full > input {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: #e7f6ff;
  border: none;
  padding: 12px 16px;
  /* height: 43px; */
}
.kickOff-input::placeholder {
  color: #afafaf;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kickOff-form-row-full {
  width: 100%;
}
.kickOff-user-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.kickOff-note {
  color: #1a1919;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.kickOff-sub-header-price {
  color: #1a1919;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kickOff-sub-header-price-line-through {
  color: #606060;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: strikethrough;
  text-decoration-line: line-through;
}
.kickOff-terms {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}
.kickOff-terms > input {
  margin-top: 5px;
  border: 1px solid #1a1919;
}
.kickOff-terms > .kickOff-terms-text {
  color: #1a1919;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.kickOff-terms > .kickOff-terms-text > a {
  text-decoration: underline;
  color: #1a1919;
}
.kickOff-pricing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.kickOff-price {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 8px;
}
.kickOff-larger-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.kickOff-larger-team-header {
  color: #1a1919;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kickOff-larger-team-sub-text {
  color: #1a1919;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  width: 100%;
}
.kickOff-slider-text {
  position: absolute;
  text-transform: uppercase;
  font-size: 18px;
  color: #1a1919;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  top: -18px;
  width: 400px;
  margin-left: calc(50vw - 550px);
}
.marquee-container-slider {
  display: block;
  position: relative;
}
.marquee-container {
  height: 107.712px;
}

.kickOff-condition {
  text-align: end;
  color: #606060;
  font-style: italic;
  font-weight: 600;
}
.landing-page-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow-y: auto;
}
.margin-12 {
  margin-top: 12px;
}
.landing-page-middle {
  display: flex;
  width: 780px;
  padding: 74px 72px;
  flex-direction: column;
  align-items: center;
  background: #c9e7f8;
  gap: 24px;
  position: relative;
}
.align-center-text {
  text-align: center;
}
.padding-10 {
  padding: 0px 10%;
}
.landing-page-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.link-container {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 6px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}
.landing-page-link > span {
  color: #1a1919;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
  text-decoration-line: underline;
  text-transform: uppercase;
}
.landing-page-logo {
  width: 107.552px;
  height: 36px;
  flex-shrink: 0;
  position: absolute;
  left: -10vw;
  top: 20px;
}
.left-container {
  padding-right: 0px;
}
.left-col {
  padding-left: 0px;
  padding-right: 7.5px;
}
.right-col {
  padding-right: 0px;
  padding-left: 7.5px;
}
.landing-page-phone {
  width: 450px;
}
/* @-webkit-keyframes marquee {
  from {
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
  }
  to {
    -webkit-transform: translateX(1000);
    transform: translateX(1000);
  }
}

@keyframes marquee {
  from {
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
  }
  to {
    -webkit-transform: translateX(1000);
    transform: translateX(1000);
  }
} */

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(-200%);
  }
  100% {
    -webkit-transform: translateX(-10%);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translateX(-200%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}
@media (max-height: 875px) {
  .landing-page-container {
    height: auto;
  }
}
@media (max-height: 750px) {
  .landing-page-container {
    height: auto;
  }
}

@media only screen and (max-width: 1080px) {
  .kickOff-wrapper-row {
    min-height: calc(100vh - 76px);
  }
  .left {
    margin-top: 23px;
    margin-right: 72px;
  }
  .kickOff-left-content {
    width: 320px;
    grid-gap: 28px;
    gap: 20px;
    margin-top: 28px;
  }
  .right-step1 {
    margin-top: 80px;
    margin-left: 72px;
  }
  .right-step2 {
    margin-top: 64px;
    margin-left: 72px;
  }
  .right-step3 {
    margin-top: 20px;
    margin-left: 72px;
  }
  .kickOff-form-container {
    padding: 18px 24px 26px 24px;
    gap: 16px;
    width: 320px;
  }
  .kickOff-form-header {
    font-size: 20px;
  }
  .kickOff-form {
    gap: 32px;
  }
  .kickOff-slider {
    width: 290px;
    display: flex;
    align-items: center;
    margin-top: 18px;
  }

  .kickOff-divider {
    background: #eaeaea;
    width: 100%;
    height: 1px;
  }
  .right-container {
    background: #80c5ee;
    padding: 0px;
  }
  .kickOff-logo {
    width: 95.602px;
    height: 32px;
  }
  .kickOff-heading {
    gap: 8px;
  }
  .kickOff-heading > p {
    font-size: 32px;
    line-height: 38px; /* 112.5% */
  }
  .kickOff-heading > span {
    font-size: 14px;
  }
  .kickoff-supporting-text {
    gap: 2px;
  }
  .kickoff-supporting-text > p {
    font-size: 14px;
  }
  .kickoff-supporting-text > a {
    font-size: 14px;
  }
  .kickoff-supporting-text > span {
    font-size: 16px;
  }
  .kickOff-user-details {
    gap: 2px;
  }
  .kickOff-user-count {
    font-size: 14px;
  }
  .kickOff-condition {
    font-size: 12px;
  }
  .kickOff-pricing {
    width: 100%;
  }
  .kickOff-price-per-user > p {
    font-size: 16px;
  }
  .kickOff-price-per-user > span {
    font-size: 14px;
  }
  .kickOff-price > p {
    font-size: 16px;
  }
  .kickOff-price > span {
    font-size: 14px;
  }
  .kickOff-total {
    font-size: 16px;
  }

  .kickOff-form-button {
    padding: 14px 26px;
    font-size: 14px;
  }
  .kickOff-help {
    font-size: 16px;
  }
  .kickOff-normal-header-text {
    font-size: 24px;
  }
  .kickOff-back > button {
    font-size: 16px;
  }
  .kickOff-sub-header {
    font-size: 20px;
    width: 75%;
  }
  .kickOff-label {
    font-size: 12px;
  }
  .kickOff-form-input > input {
    gap: 9px;
    padding: 10px 14px;
  }
  .kickOff-error {
    font-size: 10px !important;
  }
  .kickOff-form-input-full > input {
    gap: 9px;
    padding: 10px 14px;
    height: 37px;
  }
  .kickOff-input::placeholder {
    font-size: 14px;
  }
  .kickOff-form-row-full {
    width: 100%;
  }
  .kickOff-user-details {
    gap: 12px;
  }
  .kickOff-note {
    font-size: 12px;
  }
  .kickOff-terms > input {
    margin-top: 4px;
    border: 1px solid #1a1919;
  }
  .kickOff-terms > .kickOff-terms-text {
    font-size: 14px;
  }
  .kickOff-larger-team {
    gap: 8px;
  }
  .kickOff-larger-team-header {
    font-size: 20px;
  }
  .kickOff-larger-team-sub-text {
    font-size: 14px;
  }
  .marquee-container {
    height: 76px;
  }
  .landing-page-middle {
    padding: 46.5px 52px;
    width: 740px;
  }
  .landing-page-middle {
    padding: 28px 30px;
    width: 600px;
  }

  .landing-page-link > span {
    font-size: 16px;
  }
  .padding-10 {
    padding: 0 20%;
  }
  .padding-5 {
    padding: 0 10%;
  }
  .landing-page-middle {
    gap: 20px;
  }
  .landing-page-logo {
    width: 71.701px;
    height: 24px;
  }
  .kickOff-slider-text {
    margin-left: calc(50vw - 392px);
  }
}
@media (max-height: 840px) {
  /* .kickOff-slider-text {
    position: absolute;
    margin-left: calc(50vw - 550px);
  } */
}
@media screen and (min-width: 1080px) and (max-width: 1280px) {
  .landing-page-phone {
    width: 400px;
    object-fit: contain;
  }
  .kickOff-wrapper-row {
    min-height: calc(100vh - 95.744px);
  }
  .marquee-container {
    height: 95.744px;
  }
  .left {
    margin-top: 23px;
    margin-right: 72px;
    display: flex;
    justify-content: end;
  }
  .kickOff-left-content {
    width: 400px;
    gap: 40px;
    margin-top: 32px;
  }
  .right-step1 {
    margin-top: 108px;
    margin-left: 90px;
  }
  .right-step2 {
    margin-top: 80px;
    margin-left: 100px;
  }
  .right-step3 {
    margin-top: 20px;
    margin-left: 100px;
  }
  .kickOff-form-container {
    padding: 20px 32px 32px 32px;
    gap: 20px;
    width: 400px;
  }
  .kickOff-form-header {
    font-size: 24px;
  }
  .kickOff-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
  }
  .kickOff-slider {
    border-radius: 4px;
    width: 290px;
    display: flex;
    align-items: center;
    margin-top: 18px;
  }

  .kickOff-divider {
    background: #eaeaea;
    width: 100%;
    height: 1px;
  }
  .right-container {
    background: #80c5ee;
    padding: 0px;
  }
  .kickOff-logo {
    width: 95.602px;
    height: 32px;
  }
  .kickOff-heading {
    gap: 8px;
  }
  .kickOff-heading > p {
    font-size: 40px;
    line-height: 48px; /* 112.5% */
  }
  .kickOff-heading > span {
    font-size: 16px;
  }
  .kickoff-supporting-text {
    gap: 8px;
  }
  .kickoff-supporting-text > p {
    font-size: 18px;
  }
  .kickoff-supporting-text > span {
    font-size: 18px;
  }
  .kickOff-user-details {
    gap: 2px;
  }
  .kickOff-user-count {
    font-size: 14px;
  }
  .kickOff-condition {
    font-size: 14px;
  }
  .kickOff-pricing {
    width: 100%;
  }
  .kickOff-price-per-user > p {
    font-size: 16px;
  }
  .kickOff-price-per-user > span {
    font-size: 14px;
  }
  .kickOff-price > p {
    font-size: 16px;
  }
  .kickOff-price > span {
    font-size: 14px;
  }
  .kickOff-total {
    font-size: 16px;
  }

  .kickOff-form-button {
    padding: 18px 32px;
    font-size: 16px;
  }
  .kickOff-help > span {
    font-size: 16px;
  }
  .kickOff-help .underlined {
    font-size: 16px;
  }
  .kickOff-normal-header-text {
    font-size: 24px;
  }
  .kickOff-back > button {
    font-size: 16px;
  }
  .kickOff-sub-header {
    font-size: 18px;
    width: 75%;
  }
  .kickOff-label {
    font-size: 13px;
  }
  .kickOff-error {
    font-size: 10px !important;
  }
  .kickOff-form-input > input {
    gap: 9px;
    padding: 10px 14px;
  }
  .kickOff-form-input-full > input {
    gap: 9px;
    padding: 10px 14px;
    height: 37px;
  }
  .kickOff-input::placeholder {
    font-size: 14px;
  }
  .kickOff-form-row-full {
    width: 100%;
  }
  .kickOff-user-details {
    gap: 12px;
  }
  .kickOff-note {
    font-size: 14px;
  }
  .kickOff-terms > input {
    margin-top: 5px;
    border: 1px solid #1a1919;
  }
  .kickOff-terms > .kickOff-terms-text {
    font-size: 14px;
  }
  .kickOff-larger-team {
    gap: 8px;
  }
  .kickOff-larger-team-header {
    font-size: 20px;
  }
  .kickOff-larger-team-sub-text {
    font-size: 14px;
  }
  .landing-page-middle {
    padding: 46.5px 52px;
    width: 740px;
  }
  .landing-page-link > span {
    font-size: 16px;
  }
  .padding-10 {
    padding: 0 20%;
  }
  .landing-page-logo {
    width: 95.602px;
    height: 32px;
  }
  .kickOff-slider-text {
    margin-left: calc(50vw - 472px) !important;
  }
}
@media (min-width: 1280px) {
  .kickOff-slider-text {
    margin-left: calc(50vw - 550px) !important;
  }
}
@media (max-height: 888px) {
  .kickOff-form-container {
    margin-bottom: 15px;
  }
  .kickOff-left-content {
    margin-bottom: 45px;
  }
}
@media (max-height: 525px) {
  .kickOff-slider-text {
    position: relative;
  }
}
@media (max-height: 700px) {
  .landing-page-container {
  }

  .kickOff-slider-text {
    margin-left: calc(50vw - 392px);
  }
  .kickoff-supporting-text > a {
    font-size: 12px;
  }
  .kickOff-heading > p {
    font-size: 26px;
    line-height: 28px;
  }
  .kickOff-heading > span {
    font-size: 12px;
    line-height: 18px;
  }
  .kickOff-logo {
    height: 20px;
    width: auto;
  }
  .kickOff-left-content {
    margin-top: 18px;
  }
  .kickoff-supporting-text > p {
    font-size: 12px;
  }
  .kickoff-supporting-text > span {
    font-size: 12px;
  }
  .kickOff-slider-text {
    font-size: 12px;
  }
  .kickOff-left-content > img {
    width: 100%;
    object-fit: contain;
  }
  .kickOff-wrapper-row {
    min-height: calc(100vh - 76px) !important;
  }
  .kickOff-form-header {
    font-size: 18px;
  }
  .kickOff-condition {
    font-size: 12px;
  }
  .kickOff-price-per-user > p {
    font-size: 12px;
  }
  .kickOff-price > p {
    font-size: 12px;
  }
  .kickOff-price-per-user > span {
    font-size: 10px;
  }
  .kickOff-price > span {
    font-size: 10px;
  }
  .kickOff-total {
    font-size: 12px;
  }
  .kickOff-form-container {
    gap: 12px;
  }
  .kickOff-help {
    font-size: 12px;
  }
  .kickOff-form-button {
    font-size: 12px;
    padding: 10px 18px;
  }
  .kickOff-larger-team-sub-text {
    font-size: 12px;
  }
  .kickOff-larger-team-header {
    font-size: 18px;
  }
  .kickOff-normal-header-text {
    font-size: 18px;
  }
  .kickOff-sub-header {
    font-size: 14px;
  }
  .kickOff-back > button {
    font-size: 12px;
  }
  .kickOff-back > button > img {
    height: 17px;
  }
  .kickOff-label {
    font-size: 9px;
  }
  .kickOff-error {
    font-size: 7px !important;
  }
  .kickOff-form-input > input {
    font-size: 9px;
    padding: 8px 10px;
  }
  .kickOff-user-details {
    gap: 6px;
  }
  .kickOff-form-input-full > input {
    font-size: 9px;
    padding: 8px 10px;
  }
  .kickOff-input::placeholder {
    font-size: 10px;
  }
  .kickOff-sub-header-price {
    font-size: 16px;
  }
  .kickOff-sub-header-price-line-through {
    font-size: 14px;
  }
  .kickOff-terms > .kickOff-terms-text {
    font-size: 12px;
  }
  .kickOff-terms > input {
    margin-top: 2px;
  }
  .landing-page-middle {
    padding: 28px 30px;
    gap: 10px;
  }
  .landing-page-link > span {
    font-size: 14px;
  }
  .step3-stripe {
    padding: 8px 10px !important;
  }
}
@media screen and (min-height: 600px) and (max-height: 720px) {
  .landing-page-container {
  }
  .kickOff-left-content {
    margin-top: 20px;
    gap: 9px;
  }
  .landing-page-middle {
    padding: 20px 30px;
    gap: 30px;
  }
  .landing-page-link > span {
    font-size: 14px;
  }
  .landing-page-phone {
    width: 350px;
    object-fit: contain;
  }
  .kickOff-left-content {
    margin-top: 20px;
    gap: 10px;
  }
  .kickOff-heading > p {
    font-size: 35px;
    line-height: 40px;
  }
  .kickOff-heading > span {
    font-size: 16px;
    line-height: 150%;
  }
  .kickoff-supporting-text > p {
    font-size: 16px;
  }
  .kickoff-supporting-text > a {
    font-size: 16px;
  }
  .kickoff-supporting-text > span {
    font-size: 16px;
  }
  .kickOff-slider-text {
    font-size: 16px;
  }
}
@media screen and (max-height: 713px) {
  .kickOff-left-content {
    margin-top: 20px;
    gap: 15px;
    margin-bottom: 25px;
  }
}
/* kickoff */

/* Styling the slider track */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  outline: none;
}

/* Styling the slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 5px;
}

/* Left side of the thumb */
input[type="range"]::-webkit-slider-thumb::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, #abe3fc, #2f6fbf);
  border-radius: 50% 0 0 50%;
}

/* Right side of the thumb */
input[type="range"]::-webkit-slider-thumb::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: rgba(48, 127, 226, 0.3);
  border-radius: 0 50% 50% 0;
}
.kickOff-input-error {
  border: 1px solid red !important; /* Change border color */
  outline: none !important; /* Remove default focus outline */
}
.kickOff-error {
  font-size: 12px;
  color: red;
  line-height: 8px;
  width: 50%;
}
.kickOff-error-full {
  font-size: 12px;
  color: red;
  line-height: 12px;
}
.grecaptcha-badge {
  visibility: hidden;
}
.step3-stripe {
  background-color: #e7f6ff;
  width: 100%;
  border-radius: 5px;
  padding: 16px 14px;
  display: block;
}
.row-full {
  width: 100%;
}
.expiry-style {
  padding-left: 0px;
}
.cvc-style {
  padding-right: 0px;
}

.step3-stripe-error {
  border: 1px solid red;
}
.checkbox-validation {
  color: red !important;
}
.slider-container {
  width: 100%;
  display: flex;
  height: 30px;
  align-items: center;
}
@media (max-width: 400px) {
  .landing-page-middle {
    padding: 28px 0px;
    height: 100vh;
  }
  .landing-page-phone {
    width: 100%;
  }
  .kickOff-price-per-user > p {
    text-align: center;
  }
  .link-container {
    width: 280px;
  }
  .padding-10 {
    padding: 0 20% !important;
  }
}
@media (max-width: 575px) {
  .left {
    justify-content: center;
    margin-right: 0px;
  }
  .right-step1,
  .right-step2,
  .right-step3 {
    margin-left: 0px;
    justify-content: center;
    margin-bottom: 30px;
  }
  .kickOff-slider-text {
    top: -25px;
  }
  .kickOff-slider-text {
    margin-left: 0px;
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
  }
  .kickOff-left-content {
    margin-bottom: 30px;
  }
  .testAlert {
    top: 67px;
  }
}
@media (max-width: 700px) {
  .kickOff-heading > p {
    font-size: 20px;
    line-height: 24px;
  }
  .padding-10 {
    padding: 0 25%;
  }
  .kickOff-heading > span {
    font-size: 14px;
    line-height: 120%;
  }
  .link-container {
    padding: 8px 20px;
  }
  .landing-page-link > span {
    font-size: 12px;
  }
  .landing-page-middle {
    gap: 20px;
  }
  .landing-page-logo {
    display: none;
  }
}
