@media (max-width: 1650px) {
  /* .coupon-wrapper{
        padding: 0px 100px;
    } */
  .coupon-right {
    padding-right: 150px;
  }
  .coupon-left {
    padding: 150px 27px 0 100px;
  }
}
@media (max-width: 1350px) {
  /* .coupon-left {
        padding: 150px 27px 0 50px;} */
}
@media screen and (min-width: 1200px) {
  .error {
    max-width: 1360px !important;
  }
}
@media (max-width: 1199px) {
  .left-content {
    min-width: 440px;
    max-width: 440px;
    background-size: 100%;
  }

  .inner-box-contents {
    width: 1020px;
  }

  .right-content {
    width: 580px;
  }

  .actual-price {
    width: 63%;
  }

  .client-info .nav-pills .nav-link {
    font-size: 13px;
  }

  .left-content:after {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  .right-bubble {
    margin: 100px 150px;
    background-color: #80c5ee;
    padding: 16px;
    border-radius: 26px;
  }
  .right-bubble span {
    font-size: 21px;
    color: #ffff;
  }
}

@media (max-width: 1199px) {
  .signUpsection {
    max-width: 740px;
    width: 65%;
  }
}

@media (max-width: 1064px) {
  .appimg {
    width: 45%;
  }

  .footer-links ul li:last-child {
    width: 25.8%;
  }

  .right-bubble {
    margin: 100px 150px;
    background-color: #80c5ee;
    padding: 16px;
    border-radius: 26px;
  }
  .right-bubble span {
    font-size: 21px;
    color: #ffff;
  }
}

@media (max-width: 1024px) {
  .inner-box-contents {
    width: 860px;
    min-height: 550px;
  }

  .discountPercentage {
    font-size: 24px;
    font-weight: 700;
    padding-top: 15px;
  }

  .width-50:nth-child(1) {
    margin-right: 11px;
  }

  .logo img {
    width: 60%;
  }

  .logo {
    margin-bottom: 30px;
  }

  .left-content {
    min-width: 400px;
    max-width: 400px;
    background-size: 100% 100%;
  }

  .right-content {
    width: 460px;
  }

  .inner-box-contents {
    height: 460px;
  }

  .logo {
    margin-top: 70px;
  }

  .welcome-note h3 {
    font-size: 26px;
  }

  .welcome-note p {
    font-size: 13px;
  }

  .offer-inner-tag p {
    font-size: 10px;
  }

  .offer-inner-tag h2 {
    font-size: 18px;
  }

  .offer-tag {
    min-width: 125px;
    padding: 5px 13px 5px 0;
  }

  .actual-price {
    width: 63%;
    /*margin:5px 10px 0px 0;*/
  }

  .exact-amount span {
    font-size: 18px;
  }

  .exact-amount strong {
    font-size: 18px;
  }

  .exact-amount p {
    font-size: 14px;
  }

  .client-info .nav-pills .nav-link {
    font-size: 12px;
  }

  .exact-amount {
    padding-bottom: 2px;
  }

  .price-section {
    margin-bottom: 20px;
  }

  .client-info ul {
    margin-bottom: 20px !important;
  }

  .input-field label {
    font-size: 12px;
  }

  .input-field input {
    border-radius: 10px;
    /*min-height: 36px;*/
  }

  .rate-amount {
    height: 36px;
  }

  .btn-section button {
    padding: 10px 30px;
  }

  .width-60 {
    margin-right: 15px;
  }

  .width-50M {
    width: 35%;
  }

  .footer-links ul li:last-child {
    width: 25.8%;
  }

  .offerSession sup {
    position: absolute;
    top: -10px;
    background-color: #00d3de;
    padding: 14px;
    left: 20px;
    font-size: 9px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
  }

  .client-info ul a {
    font-size: 13px;
  }

  .input-field {
    margin-bottom: 12px;
  }
  .right-bubble {
    position: relative;
    margin: 40px 20px;
    background-color: #80c5ee;
    padding: 16px;
    border-radius: 26px;
  }
  .right-bubble span {
    font-size: 21px;
    color: #ffff;
  }
  .exclamation-icon {
    position: absolute;
    height: 30px;
    width: 30px;
    left: -10px;
    top: -10px;
    background-color: black;
    color: #fff;
    z-index: 10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1000px !important;
  }
  .right-bubble {
    position: relative;
    margin: 20px 20px;
    background-color: #80c5ee;
    padding: 16px;
    border-radius: 26px;
  }
  .right-bubble span {
    font-size: 21px;
    color: #ffff;
  }
  .exclamation-icon {
    position: absolute;
    height: 30px;
    width: 30px;
    left: -10px;
    top: -10px;
    background-color: black;
    color: #fff;
    z-index: 10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .signUpsection {
    max-width: 740px;
    width: 95%;
  }

  footer {
    padding: 40px 0;
  }

  .footer-links ul li a {
    font-size: 12px;
  }

  .footer-links ul li strong {
    font-size: 13px;
  }

  .mennubar nav {
    background-color: transparent;
    padding: 35px 0 25px;
  }

  @media (max-width: 768px) {
    .inner-box-contents {
      width: 720px;
      min-height: 480px;
    }

    .width-50:nth-child(1) {
      margin-right: 9px;
    }

    .input-field {
      margin-bottom: 15px;
    }

    .logo {
      margin-top: 45px;
    }

    .input-field input {
      border-radius: 10px;
      min-height: 0;
    }

    .welcome-note h3 {
      font-size: 18px;
    }

    .left-content {
      min-width: 340px;
      max-width: 340px;
      padding-left: 45px;
      padding-right: 50px;
    }

    .input-field .StripeElement {
      border-radius: 10px;
      min-height: 0;
      padding-top: 10px;
    }

    .width-35 {
      width: 36%;
    }

    .offerSession sup {
      left: 5px;
    }

    .testAlert {
      top: -60px;
    }

    .right-content {
      width: 380px;
    }

    .width-50M {
      width: 35% !important;
    }

    .error-alert .responsive-error {
      bottom: -32px;
      width: 100%;
    }

    .btn-responsive {
      position: relative;
      top: 15px;
    }

    .offer-inner-tag p {
      font-size: 8px;
    }

    .width-60 {
      width: 54%;
    }

    .offer-inner-tag h2 {
      font-size: 14px;
    }

    .offer-inner-tag h2 small {
      font-size: 11px;
    }

    .right-content {
      padding: 15px;
    }

    .actual-price {
      width: 59%;
    }

    .exact-amount span {
      font-size: 16px;
    }

    .exact-amount strong {
      font-size: 16px;
    }

    .exact-amount p {
      padding-bottom: 3px;
      font-size: 12px;
      line-height: 0.5;
      padding-top: 5px;
    }

    .exact-amount span:after {
      top: 8px;
    }

    .client-info .nav-pills .nav-link {
      font-size: 11px;
    }

    .client-info ul li:after {
      height: 24px;
    }

    .calucation-area {
      width: 75px;
    }

    .calucation-area {
      margin-bottom: 5px;
    }

    .welcome-note p {
      font-size: 12px;
    }

    .calucation-area:last-child {
      width: 37% !important;
    }

    .mennubar nav {
      padding: 30px 0 25px;
    }

    .upLink {
      text-align: center;
    }

    .upLink a:nth-child(1) {
      padding-left: 0;
    }

    .signUpsection {
      max-width: 700px;
      width: 95%;
    }
  }

  @media (max-width: 767px) {
    .error-div-inner h1 {
      font-size: 140px !important;
    }

    .error-div-inner h2 {
      font-size: 20px !important;
    }

    .error-div-inner {
      padding: 50px 0 !important;
    }
  }
}

/* html edit */

@media (max-width: 1588px) {
  .coupon-form-title {
    margin-bottom: 38px;
    font-size: 30px;
  }
  .coupon-header-text h2 {
    font-size: 65px;
  }
  .coupon-logo-div {
    left: 80px;
  }
  .coupon-right {
    padding-top: 110px;
  }
}

@media (max-width: 1499px) {
  .right-text-div h2 {
    font-size: 75px;
    line-height: 55px;
  }
  .right-text-div h3 {
    font-size: 48px;
  }
  /* .coupon-form-title {
        margin-bottom: 38px;
        font-size: 30px;
    } */
  .form-div {
    padding: 0px 35px;
  }
  /* .coupon-right {
        padding-top: 15px;
    } */
  /* .coupon-left {
        align-items: flex-end;
    } */
  /* .wrapper {
        height: auto;
    } */
}

@media (max-width: 1475px) {
  .coupon-right {
    padding-left: 70px;
    padding-right: 70px;
  }
  .coupon-header-text h2 {
    font-size: 60px;
    line-height: 65px;
  }
  .coupon-header-text {
    margin-top: 0px;
  }
  /* .coupon-form-div {
        margin-bottom: 155px;
    } */
  /* .coupon-form-title {
        font-size: 33px;
    } */
}

@media (max-width: 1399px) {
  /* .coupon-header-text {
        margin-top: -70px;
    } */
  .coupon-header-text h2 {
    font-size: 48px;
    line-height: 50px;
  }
  .web-link h3 {
    font-size: 24px;
    line-height: 70px;
  }
  .right-text-div h2 {
    font-size: 65px;
  }
  .right-text-div h3 {
    font-size: 40px;
    line-height: 45px;
    font-weight: 500;
  }
  .app-icon-app {
    margin-right: 20px;
  }
  .left-logo-div {
    max-width: 250px;
  }
  /* .contact-link a {
        font-size: 20px;
    } */
  .coupon-right {
    padding-left: 50px;
    padding-right: 50px;
  }
  .coupon-btn-div a {
    padding: 18px 25px;
    font-size: 30px;
  }

  .coupon-form-div {
    padding: 10px 0px 10px;
  }
  /* .kickoff-contact{
        bottom: 100px;
    } */
  .kickoff-contact a {
    font-size: 24px;
  }
  .kickoff-contact p {
    font-size: 24px;
  }
}

@media (max-width: 1075px) {
  /* .coupon-logo-div img {
        width: 250px;
    } */
  /* .coupon-header-text {
        margin-top: -100px;
    } */
  .coupon-header-text h2 {
    font-size: 45px;
  }
  .right-text-div h2 {
    font-size: 50px;
  }
  .right-text-div h3 {
    font-size: 35px;
    line-height: 45px;
  }
  .form-items {
    margin-bottom: 20px;
  }
  /* .coupon-form-div input {
        height: 45px;
    } */
  .form-div {
    padding: 0px 20px;
  }
  .coupon-right {
    padding-left: 40px;
    padding-right: 40px;
  }
  .coupon-check {
    font-size: 14px;
  }
}
@media (min-height: 690px) {
  body {
    overflow-y: hidden;
  }
}

@media (max-width: 990px) {
  body {
    overflow-y: auto;
  }

  .coupon-btn-div a {
    margin-bottom: 100px;
  }
  .coupon-logo-div {
    position: unset;
    padding-top: 30px;
    margin: auto;
    max-width: 250px;
  }
  .coupon-left {
    width: 100%;

    flex-direction: column;
    padding: 10px 0px 0 0px;
  }
  .coupon-header-text {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: unset;
  }

  .coupon-banner-img {
    margin-bottom: 20px;
  }
  .coupon-right {
    width: 100%;
    margin-top: 50px;
  }
  .coupon-wrapper {
    flex-direction: column;
  }
  .coupon-right {
    padding-left: 120px;
    padding-right: 120px;
  }
  .form-div {
    padding: 0px 60px;
  }
  .coupon-form-title {
    margin-bottom: 38px;
    font-size: 33px;
  }
  .coupon-form-div input {
    height: 53px;
  }
  .coupon-right {
    padding-top: 50px;
    margin-top: 0px;
  }
  .form-items {
    margin-bottom: 40px;
  }
}

@media (max-height: 689px) {
  .wrapper {
    height: auto;
  }
  /* .coupon-right {
        padding-top: 15px !important;
    } */
  /* .coupon-header-text h2 {
        font-size: 65px !important;
        line-height: 55px;
    } */
}
@media (max-width: 768px) {
  .form-items {
    margin-bottom: 20px;
  }
  .coupon-form-title {
    margin-bottom: 32px;
    font-size: 38px;
  }
  .coupon-right {
    padding-left: 80px;
    padding-right: 80px;
  }
  .form-div {
    padding: 0px 40px;
  }
  /* .coupon-form-div input {
        height: 45px;
    }
    .coupon-form-div {
        padding: 24px 0px 24px;
    } */
  .welcome-wrapper {
    flex-direction: column;
    /* position: relative; */
  }
  .left-div {
    width: 100%;
  }
  .left-banner-img > img {
    margin-top: -130px;
  }
  .left-banner-img {
    width: 100%;
  }

  .left-logo-div {
    padding-top: 15px;
    left: 0%;
    right: 0%;
    top: 0;
    margin: auto;
  }
  .left-logo-div img {
    margin: auto;
  }
  .right-div {
    margin-top: -65px !important;
    width: 100%;
    background: white;
  }
  .web-link {
    margin-bottom: 100px;
  }
  .contact-link {
    position: absolute;
  }
  /* .left-banner-img>img {
        min-height: 69vh;
    } */
}

/* @media (max-width: 669px) {
    .coupon-right {
        padding-left: 40px;
        padding-right: 40px;
    }
} */

@media (max-width: 575px) {
  .coupon-header-text h2 {
    font-size: 36px;
    line-height: 30px;
  }
  .coupon-right {
    padding-left: 10px;
    padding-right: 10px;
  }
  .coupon-form-title {
    margin-bottom: 32px;
    font-size: 30px;
  }
  .form-div {
    padding: 0px 10px;
  }
  .coupon-form-div input {
    height: 48px;
  }
  .coupon-btn-div a {
    padding: 14px 20px;
    font-size: 24px;
  }
  .web-link h3 {
    font-size: 22px;
    line-height: 70px;
  }
  .right-text-div h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .right-div {
    padding-top: 0px;
    margin-top: 50px;
  }
  .right-text-div h3 {
    font-size: 25px;
    line-height: 45px;
  }
  .app-icon-btn {
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
  }
  .web-link h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .left-logo-div {
    max-width: 200px;
  }
}

@media (max-width: 414px) {
  .coupon-logo-div img {
    max-height: 70px;
  }
  .coupon-header-text h2 {
    font-size: 28px;
    line-height: 40px;
  }
  .coupon-form-title {
    margin-bottom: 32px;
    font-size: 24px;
  }
  .coupon-form-div label {
    font-size: 15px;
  }
  .coupon-form-div input {
    height: 45px;
  }
  .form-items {
    margin-bottom: 10px;
  }
  .coupon-form-div {
    padding: 20px 0px 23px;
  }
  .coupon-right {
    padding-left: 5px;
    padding-right: 5px;
  }
}
